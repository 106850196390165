module.exports
{

    app = angular.module('app', ['ui.router','ngStorage','vcRecaptcha','ngMask','ui-notification']); //'angular-uuid','angucomplete-alt','ui.utils.masks'
    serverurl='https://donation.anoopam.org/api/v1';

   // console.log(IS_DEV);

    app.run(['$rootScope', '$state', '$stateParams','$transitions','$localStorage','$timeout',function ( $rootScope,   $state,   $stateParams,$transitions,$localStorage,$timeout ) {
            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;
            $transitions.onSuccess({}, function() {
                if($rootScope.signIn){
                    $timeout(function () {
                        $localStorage.lastvisit = window.location.href; // a})DwCu{@gg{
                    },2000)
                }
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
    }]);

    app.run(function ($rootScope, $templateCache) {
        $rootScope.$on('$viewContentLoaded', function () {
            $templateCache.removeAll();
        });
    });

    app.config(function(NotificationProvider) {
        NotificationProvider.setOptions({
            delay: 10000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'left',
            positionY: 'bottom'
        });
    });

    app.config(['$locationProvider', function ($locationProvider) {
        //$locationProvider.html5Mode({enabled: true});
        $locationProvider.hashPrefix('');
    }]);

    var date = new Date().getTime().toString();

    app.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/members');
        $stateProvider

        .state('members', {
            url: '/members',
            templateUrl: 'views/members.html?t='+date,
            controller: 'membersController',
            cache: false,
            data: {title: 'Members', theme: 'dark'}
        })

       

    }]);

}


