

app.controller('AppController', ['$scope', '$rootScope', 's', '$location','$state',  '$compile', '$http', '$timeout','$localStorage','$filter', function ($scope, $rootScope, s, $location,$state,  $compile, $http,$timeout,$localStorage,$filter) {

      $scope.currentPage = 1;
      $scope.pageSize = '25';

      $scope.states = {
          "AN":"Andaman and Nicobar Islands",
          "AP":"Andhra Pradesh",
          "AR":"Arunachal Pradesh",
          "AS":"Assam",
          "BR":"Bihar",
          "CG":"Chandigarh",
          "CH":"Chhattisgarh",
          "DN":"Dadra and Nagar Haveli",
          "DD":"Daman and Diu",
          "DL":"Delhi",
          "GA":"Goa",
          "GJ":"Gujarat",
          "HR":"Haryana",
          "HP":"Himachal Pradesh",
          "JK":"Jammu and Kashmir",
          "JH":"Jharkhand",
          "KA":"Karnataka",
          "KL":"Kerala",
          "LA":"Ladakh",
          "LD":"Lakshadweep",
          "MP":"Madhya Pradesh",
          "MH":"Maharashtra",
          "MN":"Manipur",
          "ML":"Meghalaya",
          "MZ":"Mizoram",
          "NL":"Nagaland",
          "OR":"Odisha",
          "PY":"Puducherry",
          "PB":"Punjab",
          "RJ":"Rajasthan",
          "SK":"Sikkim",
          "TN":"Tamil Nadu",
          "TS":"Telangana",
          "TR":"Tripura",
          "UP":"Uttar Pradesh",
          "UK":"Uttarakhand",
          "WB":"West Bengal"
      }

      

     $scope.getInit = function(callback){
          s.callback('init',{},'GET',true,false,function (response) {
               $scope.init = response.init.data;
               //$scope.init.selected={categoryid:12,subcategoryid:31,towards:'Religious'};
               callback();
          });
     }

 

     $scope.submit= function ($event,action,data,params,method,loader,retry,callback) {

          if($event){
               var element=$($event.currentTarget);
               if($event.currentTarget.tagName == 'FORM'){
                    element = $('[type="submit"]',$event.currentTarget);
               }
               var value = element.html();
               element.attr({disabled:true}).html('<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...');
          }
          var url = action;
          if(params){
               url += jsonToQueryString(params);
          }

          s.callback(url,data,method,loader,retry,function (res) {

               let response = res[action];

               if(response){
                    if(response.code === 'retry'){
                         callback({action:'retry'});
                    }
                    else{
                         if(response.status==='success' || response.status==='info'){
                              if(callback){
                                   callback(response);
                              }
                              $rootScope.notify(response.status,response.msg);
                         }
                         if(element){
                              element.attr({disabled:false}).html(value);
                         }
                    }
               }
          });
     }

     var timer;
     $scope.filterInput = function (value) {
          $timeout.cancel(timer);
          timer = $timeout(function () {
               $rootScope.filter = value;
          },500)
     }

     $scope.replace = function (master,chunk,field) {
          const obj = ($filter('filter')(master, field, true)[0]);
          const key = master.indexOf(obj);
          master[key] = chunk;
     }

     $scope.findby = function (master,field) {
         return $filter('filter')(objToArray(master), field, true);
     }

    $scope.findandreplace = function (items,searchfield,replacewith) {
        items.forEach((element, index) => {
            if(element[searchfield] === replacewith[searchfield]) {
                items[index] = replacewith;
            }
        });
    }


     $scope.removeitem = function (master,item,callback) {
          if(confirm('Are you sure to delete?')) {
               const index = master.indexOf(item);
               master.splice(index, 1);
               return callback('deleted');
          }
          return callback('cancel');
     }


     $rootScope.notify = function (status,msg) {
          //Notification({message: msg}, status);
          $rootScope.alert = {'msg':msg,'status':status};
          $timeout(function () {
               $rootScope.alert.msg='';
          },4000);
     }

     $scope.print = function(){
          window.print();
     }

     $scope.jsontocsv = function(json,fields,filename){
          JSONToCSVConvertor(json,fields,filename,true);
     }

     $scope.isSignIn = function () {
          if($localStorage.init){
               $rootScope.loginuser = $localStorage.init;
               $rootScope.signIn=true;
               return true;
          }
          else{
               return false;
          }
     }

     $scope.logout = function(){
          $localStorage.init = false;
          $rootScope.signIn = false;
          $scope.isSignIn();
         $location.path('login');
     }



     $scope.checkPan = function(pannumber){

          var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

          if(regpan.test(pannumber)){
               return true;
          } else {
               $rootScope.notify('danger','PAN Number is wrong');
               return false;
          }
     }

     $scope.modalConfirm = function(callback){
          $("#mi-modal").modal('show');
          $("#modal-btn-yes").on("click", function(){
               $("#mi-modal").modal('hide');
               callback(true);
          });
          $("#modal-btn-no").on("click", function(){
               $("#mi-modal").modal('hide');
               callback(false);
          });
        };
}]);


app.controller('membersController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams,$window) {
 
     $scope.amounttype = 'sameforall';
     $scope.donationamount = 100;
    
     let member = {firstname:'',lastname:'',middlename:'',phone:'',purpose:'',tmppurpose:$scope.purpose}
     $scope.members = [];

     $scope.addfamiymember = function($event){                         
          $scope.members.push(clone(member));
          $scope.setAmount();  
          $scope.setPurpose(); 
     }

     $scope.setAmount = function(){
          if($scope.amounttype == 'sameforall'){
               $scope.members.map((member)=>{
                    member.seva = $scope.donationamount
               })               
          } 
          if($scope.amounttype == 'devideforall'){
               let devideamount = parseInt($scope.donationamount / $scope.members.length);                
               $scope.members.map((member)=>{
                    member.seva = devideamount
               })               
          } 
     }

     $scope.setPurpose = function(){           
          if($scope.samepurpose){
               $scope.members.map((member)=>{
                    member.purpose = member.tmppurpose || $scope.purpose;
               })               
          } 
          else{
               $scope.members.map((member)=>{
                    member.purpose = member.tmppurpose;
               }) 
          }          
     }


     $scope.addfamiymember();
 

     $scope.register = function($event){
          $scope.submit($event,'registration',$scope.data,'','POST',true,true,function(response){
               $scope.registrationsuccess = true;
          });
     }

  

}]);

 


function jsonToQueryString(json) {
     return '/?' +
         Object.keys(json).map(function(key) {
              return encodeURIComponent(key) + '=' +
                  encodeURIComponent(json[key]);
         }).join('&');
}

function copy(mainObj) {
     let objCopy = {};
     let key;

     for (key in mainObj) {
          objCopy[key] = mainObj[key];
     }
     return objCopy;
}

function clone(obj){
     var copy;
     if (null == obj || "object" != typeof obj) return obj;
     if (obj instanceof Date) {
          copy = new Date();
          copy.setTime(obj.getTime());
          return copy;
     }
     if (obj instanceof Array) {
          copy = [];
          for (var i = 0, len = obj.length; i < len; i++) {
               copy[i] = clone(obj[i]);
          }
          return copy;
     }
     if (obj instanceof Object) {
          copy = {};
          for (var attr in obj) {
               if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
          }
          return copy;
     }
     throw new Error("Unable to copy obj! Its type isn't supported.");
}


function printDiv() {
     window.print();
 }

function objToArray(data){
    if (Array.isArray(data)) {
        return data;
    }
    if (data) {
        let result = [];
        for (let i in data) {
            if (i && data[i]) {
                result.push(data[i]);
            }
        }
        result.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
        return result;
    }
}


//  Https://3.23.155.150:2083
//  newsiteanoopam
//  a})DwCu{@gg{
